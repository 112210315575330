<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      coordinatingCommittees: 'coordinatingCommittees/data',
    }),
    technicalCommittees() {
      const technicalCommittees = [];
      Object.values(this.coordinatingCommittees).forEach((cc) => {
        Object.values(cc.technicalCommittees).forEach((tC) => {
          const tcExists = technicalCommittees.find((tc) => tc.id === tC.id);

          if (!tcExists) {
            technicalCommittees.push({
              id: tC.id,
              name: tC.name,
            });
          }
        });
      });
      return technicalCommittees;
    },
  },
};
</script>
