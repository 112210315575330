<template>
  <div class="container conference-list">
    <div class="card conference-card border-0 shadow bg-light">
      <div class="card-body" :class="[conference ? 'has-summary' : '']">
        <ifac-loader v-if="busy" style="height: 750px"/>
        <div class="row" v-else>
          <div class="col-12">
            <ifac-conference-summary v-bind="conferenceSummaryOptions"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { IfacLoader, IfacConferenceSummary } from '@ifac/ui';
import Committees from '@/services/mixins/Committees.vue';
import Conferences from '@/services/Api/Conferences';

export default {
  components: {
    IfacLoader,
    IfacConferenceSummary,
  },
  mixins: [Committees],
  created() {
    this.fetchConference();
  },
  data() {
    return {
      busy: true,
      conference: null,
    };
  },
  computed: {
    ...mapGetters({
      countries: 'countries/data',
      conferenceTypes: 'conferenceTypes/data',
      uncompletedSections: 'conferences/uncompletedSections',
    }),
    conferenceId() {
      return this.$route.params.id;
    },
    conferenceSummaryOptions() {
      return {
        isOwner: true,
        withPdf: true,
        readOnly: true,
        withHeader: true,
        withSubmission: true,
        countries: this.countries,
        conference: this.conference,
        conferenceTypes: this.conferenceTypes,
        technicalCommittees: this.technicalCommittees,
        uncompletedSections: this.uncompletedSections(this.conference),
      };
    },
  },
  methods: {
    async fetchConference() {
      this.busy = true;
      try {
        const response = await Conferences.get(this.conferenceId);
        const conferenceData = response?.data?.data;

        if (response?.status === 200 && conferenceData) {
          this.conference = conferenceData;
        }
      } catch (error) {
        console.log(error);
      }
      this.busy = false;
    },
  },
};
</script>
