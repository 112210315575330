<template>
  <b-modal
    hide-footer
    ref="cancel-conference"
    id="cancel-conference"
    title="Cancel Conference"
  >
    <div class="row">
      <div class="col-12">
        <h3 class="mb-2">Do you want to cancel the current Conference?</h3>
        <p>You should take into account that the current saved information will be lost.</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <b-button
          block variant="default border"
          @click="cancel"
        >Do not Cancel
        </b-button>
      </div>
      <div class="col-6">
        <b-button
          block
          variant="primary"
          @click="proceed"
        >Proceed</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Conferences from '@/services/Api/Conferences';

export default {
  name: 'CancelConferenceModal',
  props: {
    conference: Object,
  },
  methods: {
    cancel() {
      this.$refs['cancel-conference'].hide();
    },
    async proceed() {
      if (this.conference.id) {
        try {
          await Conferences.delete(this.conference.id);
          this.$snack.success({
            text: 'Conference Cancelled!',
          });
          this.hideAndRedirect();
        } catch (error) {
          console.error(error);
          this.$snack.error({
            text: 'Problem cancelling the conference, try again please.',
          });
        }
      } else this.hideAndRedirect();
    },
    hideAndRedirect() {
      this.$router.push({ name: 'Conferences' });
      this.$refs['cancel-conference'].hide();
    },
  },
};
</script>
