<template>
  <b-modal
    size="xl"
    hide-footer
    hide-header
    ref="submit-conference"
    id="submit-conference"
  >
    <div class="card border-0 text-center">
      <div class="card-body p-5">
        <b-icon
          icon="check-circle"
          class="mt-5"
          style="width: 80px; height: 80px"
        ></b-icon>
        <h5 class="card-title mt-4">Your application has been submitted successfully</h5>
        <p class="card-text text-left mt-4">
          Thank you for your proposal to host {{ conference?.title?.short }}. Your application will now follow the following steps:
        </p>
        <ol class="text-left pl-3 list-style-type-none">
          <li>(a) It will be verified by the Secretariat that may ask you for some additional input (in which case the application form will be editable again)</li>
          <li>(b) Secretariat will send you a PDF version of your application with request of signing it and obtaining the signature of your NMO representative.</li>
          <li>(c) The application will then be transmitted to the Technical Board for evaluation by the Technical Committee Chairs. Your application cannot be modified during evaluation.</li>
        </ol>
        <p class="card-text text-left">
          Approval/rejection decision is expected within 4-6 weeks.
        </p>
        <a
          href="#"
          class="btn btn-primary mt-4 mb-5"
          @click.prevent="confirm"
        >Confirm
        </a>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'SubmitConferenceModal',
  props: {
    conference: Object,
  },
  methods: {
    confirm() {
      this.$router.push({ name: 'Conferences' });
      this.$refs['submit-conference'].hide();
    },
  },
};
</script>

<style>
  .list-style-type-none {
    list-style-type: none;
  }
</style>
